import Vue from 'vue';
import Vuex from 'vuex';
import shortid from 'shortid';

import user from './modules/user'
import auth from './modules/auth'
import outbound from './modules/outbound'
import inbound from './modules/inbound'
import transfer from './modules/transfer'
import inventory from './modules/inventory'
import warehouse from './modules/warehouse'

import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex);

const state = {
    // API Data general
    loading: false,
    loading_devices: [],
    templates: [],
    articles: [],
    article_units: [],
    available_special_actions: [],
    customers: [],
    messages: [],
    gate: {}, // TODO: move to submodule Inbound.
    delivery_note: null,
    created_inbound: null,
    custom_1: null,
    custom_2: null,
    customer: null,
    storage_units: [],

}
const getters = {
    getField, getCustomerName: state => {
        let customer = state.customers.find(item => item.id === state.customer);
        if (customer) {
            return customer.name;
        } else {
            return "";
        }
    }, storageUnitsCount: state => {
        return state.storage_units.length;
    }, getStorageUnitById: (state) => (id) => {
        return state.storage_units.find(item => item.id === id)
    },

    getNextStorageUnit: (state) => (id) => {
        const current = state.storage_units.findIndex(item => item.id === id);
        if (state.storage_units[current + 1] !== undefined) {
            return state.storage_units[current + 1];
        } else {
            return undefined;
        }
    }, getPrevStorageUnit: (state) => (id) => {
        const current = state.storage_units.findIndex(item => item.id === id);
        if (state.storage_units[current - 1] !== undefined) {
            return state.storage_units[current - 1];
        } else {
            return undefined;
        }
    }, getLastStorageUnit: (state) => {
        if (state.storage_units.length > 0) {
            return state.storage_units[state.storage_units.length - 1];
        }
        return undefined;
    },
}
const mutations = {
    updateField, // GLOBAL
    addMessage(state, payload) {
        state.messages.push(payload);
    }, setGate(state, payload) {
        state.gate = payload;
    }, resetGate(state) {
        // do not reset warehouse in halle 1
        if(parseInt(localStorage.getItem('warehouse')) !== 1){
            state.gate = {};
        }
    }, setAvailableSpecialActions(state, payload) {
        state.available_special_actions = payload
    }, removeMessages(state) {
        state.messages = [];
    }, receive_loading_devices(state, devices) {
        state.loading_devices = devices
    }, receive_templates(state, templates) {
        state.templates = templates;
    }, receive_articles(state, articles) {
        state.articles = articles;
    }, receive_customers(state, data) {
        state.customers = data
    }, receive_warehouse(state, data) {
        state.warehouse = data
    }, receive_user(state, user) {
        state.user = user;
    }, // FOR INBOUND
    updateCustomer(state, value) {
        state.customer = value
    }, setLoading(state, value) {
        state.loading = value
    }, updateDeliveryNote(state, value) {
        state.delivery_note = value
    }, updateCustom1(state, value) {
        state.custom_1 = value
    }, updateCustom2(state, value) {
        state.custom_2 = value
    }, addStorageUnit(state, payload) {
        let id = shortid.generate();
        let number = payload.number ? payload.number : state.storage_units.length + 1;
        if (!payload.loading_device_id) {
            payload.loading_device_id = state.loading_devices_default;
        }
        const loadingType = state.loading_devices.find(item => item.id === payload.loading_device_id);
        let obj = {
            id: id,
            name: number,
            nve: payload.nve ? payload.nve : '',
            loading_device_id: loadingType.id,
            image: payload.image,
            type_name: loadingType.name,
            type_name_short: loadingType.short_name,
            name_full: number,
            storage_unit_articles: [],
        };
        obj = {...obj, ...payload}
        state.storage_units.push(obj);
    }, copyStorageUnit(state, payload) {

        let storage_unit = state.storage_units.find(item => item.id === payload.id);
        let i = 0;
        while (i < payload.packaging_unit_count) {
            i++;
            // copy = null;
            let copy = JSON.parse(JSON.stringify(storage_unit))

            copy.id = shortid.generate();
            copy.storage_unit_articles.forEach(function (article, index) {
                if (payload.batches && payload.batches[i]) {
                    this[index].batch = payload.batches[i];
                }
                this[index].id = shortid.generate();
            }, copy.storage_unit_articles)

            copy.name_full = copy.name_full + " - Kopie " + i;

            state.storage_units.push(copy);
        }
    }, updateStorageUnitImage(state, payload) {
        let storageUnit = state.storage_units.find(item => item.id === payload.storage_unit_id);
        Vue.set(storageUnit, 'image', payload.image);
    }, updateStorageUnitType(state, payload) {
        let storageUnit = state.storage_units.find(item => item.id === payload.storage_unit_id);
        const loadingType = state.loading_devices.find(item => item.id === payload.loading_device_id);
        Vue.set(storageUnit, 'loading_device_id', payload.loading_device_id);
        Vue.set(storageUnit, 'type_name', loadingType.name);
        Vue.set(storageUnit, 'type_name_short', loadingType.short_name);
    }, addArticleToStorageUnit(state, payload) {
        let storageUnit = state.storage_units.find(item => item.id === payload.storage_unit);
        payload.id = shortid.generate();
        storageUnit.storage_unit_articles.push(payload);
    }, updateArticle(state, payload) {
        let storageUnit = state.storage_units.findIndex(item => item.id === payload.storage_unit_id);
        let articleIndex = state.storage_units[storageUnit].storage_unit_articles.findIndex(item => item.id === payload.article_id);

        Vue.set(state.storage_units[storageUnit]['articles'], articleIndex, payload.article);
    }, deleteArticle(state, payload) {
        let storageUnit = state.storage_units.findIndex(item => item.id === payload.storage_unit_id);
        let articlesNew = state.storage_units[storageUnit].storage_unit_articles.filter(function (item) {
            return item.id !== payload.article_id
        });

        Vue.set(state.storage_units[storageUnit], 'articles', articlesNew);
    }, deleteStorageUnit(state, payload) {
        let storageUnit = state.storage_units.filter(item => item.id !== payload.storage_unit_id);
        Vue.set(state, 'storage_units', storageUnit);
    }, cleanupStorageUnits(state) {
        state.storage_units = state.storage_units.filter(item => item.storage_unit_articles.length > 0);
    }, created_inbound(state, payload) {
        Vue.set(state, 'created_inbound', payload);
    }, resetState(state) {
        state.storage_units = [];
        state.delivery_note = null;
    },
    resetStorageUnits(state) {
        state.storage_units = [];
    }
}

const actions = {
    // GLOBAL
    setGate({commit}, payload = {}) {
        commit('setGate', payload);
    }, resetGate({commit}) {
        commit('resetGate');
    }, setAvailableSpecialActions({commit}, payload) {
        commit('setAvailableSpecialActions', payload)
    }, getUserData({commit}) {
        Vue.intranet.get("client").then(function (response) {
            commit('receive_customers', response.data.data)
        })
        const warehouse_id = parseInt(localStorage.getItem('warehouse'))
        Vue.intranet.get(`/auth-warehouse`, {
            params: {
                include: ['gates']
            }
        }).then(function (response) {
            commit("warehouse/setWarehouses", response.data.data);
            let current_warehouse = response.data.data.find((item) => item.id === warehouse_id);
            commit("warehouse/setGates", current_warehouse);
        })
    },

    // INBOUND
    createStorageUnits({commit}, payload) {
        let i = 0;
        while (i < payload.packaging_unit_count) {
            commit('addStorageUnit', {
                loading_device_id: payload.loading_device_id
            });
            i++;
        }
    },
    async updateCustomer({commit}, payload) {

        // UPDATE CURRENT CUSTOMER
        commit('updateCustomer', payload);
        commit('setLoading', true);

        Vue.intranet
            .get("loading-device")
            .then(({data}) => commit('receive_loading_devices', data))

        Vue.intranet
            .get("template", {params: {filter: {client_id: payload}}})
            .then(({data}) => commit('receive_templates', data.data))

        // await Vue.intranet
        //     .get("article", {params: {filter: {client_id: payload}}})
        //     .then(({data}) => commit('receive_articles', data.data))

        commit('setLoading', false);


    },
    sendInboundCreate({commit, state}) {

        const count_before = state.storage_units.length;
        commit("cleanupStorageUnits");
        const count_after = state.storage_units.length;

        if (count_before > count_after) {
            commit("addMessage", {
                type: "info", message: "Es wurden " + (count_before - count_after) + " leere Paletten entfernt.",
            });
        }

        let requestObject = {
            storage_units: {...state.storage_units},
            delivery_note_number: state.delivery_note,
            custom_1: state.custom_1,
            custom_2: state.custom_2,
            client_id: state.customer,
        };

        return Vue.intranet.post('inbound-create-from-mde', requestObject).then(function ({data}) {
            commit('created_inbound', data.data);
            commit('resetState');
            commit('resetGate');
        });
    }, addArticleToStorageUnit({commit}, payload) {
        commit('addArticleToStorageUnit', payload);
    }, deleteArticle({commit}, payload) {
        commit('deleteArticle', payload);
    }, deleteStorageUnit({commit}, payload) {
        commit('deleteStorageUnit', payload);
    }, copyStorageUnit({commit}, payload) {
        commit('copyStorageUnit', payload);
    }
}

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production', modules: {
        user, auth, outbound, inbound, transfer, inventory, warehouse
    }, state, mutations, getters, actions
});

if (module.hot) {

    module.hot.accept(['./modules/inbound', './modules/outbound', './modules/user', './modules/auth', './modules/transfer', './modules/inventory', './modules/warehouse'], () => {

        const inbound = require('./modules/inbound').default
        const outbound = require('./modules/outbound').default
        const auth = require('./modules/auth').default
        const user = require('./modules/user').default
        const transfer = require('./modules/transfer').default
        const inventory = require('./modules/inventory').default
        const warehouse = require('./modules/warehouse').default

        // swap in the new modules and mutations
        store.hotUpdate({
            getters: getters, actions: actions, mutations: mutations, modules: {
                inbound, outbound, auth, user, transfer, inventory, warehouse
            }
        })
    })
}

export default store